export default function Cursor() {
    if(document.querySelector(".cursor")){

        let mouseCursor = document.querySelector(".cursor");
        let mouseCursor2 = document.querySelector(".gradient");


        window.addEventListener('DOMContentLoaded', cursor);
        window.addEventListener('mousemove', cursor);
        document.addEventListener('mouseenter', () => mouseCursor.style.display = 'block');
        document.addEventListener('mouseleave', () => mouseCursor.style.display = 'none');
        document.addEventListener('mouseenter', () => mouseCursor2.style.display = 'block');
        document.addEventListener('mouseleave', () => mouseCursor2.style.display = 'none');

        mouseCursor.style.display = 'block';
        mouseCursor2.style.display = 'block';

        function cursor(e){
            mouseCursor.style.top = "calc(" +e.clientY + "px - 1rem)";
            mouseCursor.style.left = "calc(" +e.clientX + "px - 1rem)";
            mouseCursor2.style.top = "calc(" +e.clientY + "px - 6.4rem)";
            mouseCursor2.style.left = "calc(" +e.clientX + "px - 6.2rem)";
        }
    }
}