import './Header.css';
import React from 'react';
import { Slide } from "react-awesome-reveal";

export default function Header() {

    return (
        <div className='header-background'>
            <div className='header'>
                <Slide direction='right' fraction={1}>
                    <p style={{color:"#f5f5f5"}}>George Kandamkolathy</p>
                </Slide>
                <Slide direction='left' className='link'>
                    <a className="link" href="https://github.com/GeorgeKandamkolathy">Github</a>
                </Slide>
            </div> 
        </div>

  );
}

