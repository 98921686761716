import './App.css';
import React, {useEffect, useState} from 'react';
import { Fade } from "react-awesome-reveal";
import Header from './components/Header';
import chesma from './images/Chesmabattle_edit.png';
import Cursor from './components/Cursor';

function App() {
  const [expandList, setExpandList] = useState(1)

  useEffect(() => {
    Cursor()
  })

  return (
    <div className="App">
      <Header/>
      <div className='gradient'>
        <div className="cursor">
        </div>
      </div>
      <div style={{ width: 'auto', height:'auto', backgroundImage:`url(${chesma})`}}>
        <div style={{marginTop:"26rem", marginLeft:'3rem'}}>
          <Fade triggerOnce duration={1700} cascade>
            <h1 style={{ fontSize:130, marginBottom:0, lineHeight:1, width:"25%",color:'#f5f5f5', fontFamily:'Avenir Next Condensed', fontWeight:600, letterSpacing:-1}}>GEORGE KANDAMKOLATHY</h1>
            <h3 style={{ fontSize:80, marginTop:0, fontWeight:'lighter',  fontFamily:'baskerville', color:'#f5f5f5'}}>Software Engineer</h3>
          </Fade>
        </div>
        </div>

      <Fade triggerOnce>
        <h3 style={{fontSize:80, marginBottom:10, marginLeft:"3rem"}}>EXPERIENCE</h3>
      </Fade>
      <Fade triggerOnce cascade duration={1000} delay={500}>
        <ul style={{ width:'95%' , marginLeft:"2rem"}}>
          <li className='List-item'><p>Bachelor of Computer Science/Economics, University of New South Wales</p><p style={{fontWeight:'lighter'}}>Feb 2020 - Nov 2023 (Expected)</p></li>
          <li className='List-item'><p>Fullstack Software Developer, Orefox</p><p style={{fontWeight:'lighter'}}>Oct 2021 - Present</p></li>
          <li className='List-item'><p>Volunteer Software Developer, Race to Raise</p><p style={{fontWeight:'lighter'}}>Oct 2020 - Feb 2021</p></li>
          <li className='List-item'><p>Volunteer Software Developer, Fortunate Food Co</p><p style={{fontWeight:'lighter'}}>Oct 2021 - Feb 2022</p></li>
        </ul>
      </Fade>

      <Fade triggerOnce>
        <h3 style={{fontSize:80, marginBottom:10, marginLeft:"3rem"}}>PROJECTS</h3>
      </Fade>

      <div>
        <div style={{display:'flex', marginLeft:'5rem'}}>
            <div className={expandList === 1 ? "verticalList expandedList" : "verticalList"} onMouseEnter={(event) => {setExpandList(1)}}><h3 className='numTitles'>1</h3>
            <h3 style={{marginLeft:'1.5rem'}}>Earworm</h3>
            <div className="pillGroup" style={expandList === 1 ? {} : {opacity:'0', transition:'all 2s'}}>
              <div className="pill" style={{width:"50%", color:'#f5f5f5'}}>NodeJS</div>
              <div className="pill" style={{backgroundColor:'#f7709d', width:"50%"}}>ReactJS</div>
              <div className="pill" style={{backgroundColor:'#f7709d', width:"50%"}}>SocketIO</div>
            </div>
            <p style={{marginLeft:'1.5rem'}}>
              NodeJS and ReactJS web game where players attempt to guess a song based on the opening built using socketIO, and Soundcloud API.
              Uses socketIO to manage multiple users playing the game at once, uses Soundcloud API to stream songs.
            </p>
            </div>
            <div className={expandList === 2 ? "verticalList expandedList" : "verticalList"} onMouseEnter={(event) => {setExpandList(2)}}><h3 className='numTitles'>2</h3> 
            <h3 style={{marginLeft:'1.5rem'}}>Local to Spotify</h3>
            <div className="pillGroup" style={expandList === 2 ? {} : {opacity:'0', transition:'all 1s'} }>
              <div className="pill" style={{color:'#f5f5f5'}}>Python</div>
              <div className="pill" style={{backgroundColor:'#f7709d'}}>Spotify API</div>
            </div>
            <p style={{marginLeft:'1.5rem', marginRight:'3rem'}}>
              Python program which creates a Spotify playlist based on local music files. 
              Uses Spotify API to login and authorise, and file metadata reader to get file data and add to users library.
            </p>
            </div>
            <div className={expandList === 3 ? "verticalList expandedList" : "verticalList"} onMouseEnter={(event) => {setExpandList(3)}}><h3 className='numTitles'>3</h3>
            <h3 style={{marginLeft:'1.5rem'}}>LitSub</h3>
            <div className="pillGroup" style={expandList === 3 ? {} : {opacity:'0', transition:'all 1s', width:'0'}}>
              <div className="pill" style={{color:"#f5f5f5"}}>Python</div>
              <div className='pill' style={{color:"#f5f5f5"}}>ReactJS</div>
              <div className="pill" style={{backgroundColor:'#f7709d'}}>Django REST</div>
              <div className="pill" style={{backgroundColor:'#f7709d'}}>TailwindCSS</div>
            </div>
            <p style={{marginLeft:'1.5rem'}}>
            Web service to post and read literature built using Django and Django REST for the backend and ReactJS and TailwindCSS for the frontend.
            Has the ability to write and upload literature, write comments, and like.
            Also has the ability to tag stories and search.
            </p>
            </div>
            <div className={expandList === 4 ? "verticalList expandedList" : "verticalList"} onMouseEnter={(event) => {setExpandList(4)}}><h3 className='numTitles'>4</h3>
            <h3 style={{marginLeft:'1.5rem'}}>Flashcards</h3>
            <div className="pillGroup"  style={expandList === 4 ? {} : {opacity:'0', transition:'all 2s'}}>
              <div className="pill" style={{width:"50%", color:'#f5f5f5'}}>React Native</div>
              <div className="pill" style={{backgroundColor:'#f7709d', width:"50%"}}>SQLite</div>
            </div>
            <p style={{marginLeft:'1.5rem'}}>
            A flashcard mobile application built with react native that uses SQLite as the database. 
            With the ability to create sets of cards, categorise them and uses the Lietner Method of memorisation.
            </p>
            </div>
       </div>
      </div>

      <Fade triggerOnce>
      <h2 style={{fontSize:80, marginBottom:10, marginLeft:'3rem'}}>CONTACT ME</h2>
      </Fade>
      <p style={{fontSize:20, marginLeft:'5rem'}}>Email me at <a className="link" href="mailto:george.kandamkolathy@gmail.com">george.kandamkolathy@gmail.com</a> or find my projects on my <a className="link" href="https://github.com/GeorgeKandamkolathy">GitHub</a></p>
      </div> 
  );
}

export default App;
